import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Logo from "../images/svg/logo.svg";

import TooEasy from "../images/too_easy.png";
import MapMatchmaking from "../images/map_matchmaking.png";
import Neighborhood from "../images/neighborhood_priority.png";
import Unlock from "../images/unlock_the_ringtone.png";
import PlayStoreWhite from "../images/play_store_icon_white.png";
import Waveform from "../images/waveform.png";

import { ParallaxBanner } from 'react-scroll-parallax';
import Image, {Image2} from "../components/image";


const IndexPage = () => (
    <Layout>
        <SEO title="Koyeto" keywords={[`Koyeto`, `easy to use`, `orange`, `anonymous`, `free`, `dialogue`, `next generation`, `shuffle logo`, `calls with strangers`, `unknown length`, `distance based`, `neighborhood`, `nearby`, `geolocation`, `serbian startup`]}/>

            <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Image2 src={"main.png"} style={{display: 'flex', flex: 1}} className={"main"}/>
                <div style={{position: 'absolute', top: 0, width: '100%', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{maxWidth: 960, width: '100%', display: 'flex', flex: 1, padding: `15px`}}>
                        <div style={{width: '50%'}}/>
                        <div style={{width: '50%', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <div className={"button-hover button-active"}>
                                <a style={{
                                        color: `white`,
                                        textDecoration: `none`,
                                    }}
                                    href="https://play.google.com/store/apps/details?id=app.koyeto.android"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={PlayStoreWhite} alt="" className={"icon-size button button-white animate-button animate-button-play-store"}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{position: 'absolute', maxWidth: '320px', textAlign: 'center', marginTop: '-2rem'}}>
                    <Logo style={{width: '60%', padding: '0 10%', marginBottom: '4%'}}/>
                    <h1 style={{color: 'white'}}>Koyeto</h1>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={Waveform} alt="" className={"waveform opposite"}/>
                        <h2 style={{color: 'white', margin: 'auto 7px'}}>next generation social networking</h2>
                        <img src={Waveform} alt="" className={"waveform"}/>
                    </div>
                </div>
            </div>

            <div style={{width: '100%', flex: 1, display: 'flex', justifyContent: 'center'}}>
                <div style={{maxWidth: 960, padding: '5% 0'}}>
                    <div style={{flex: 1, alignItems: 'center'}} className={"row"}>
                        <div className={"col-6"}>
                            <h3 className={"right"}>Too Easy</h3>
                            <h4 className={"description right"}>One feature, one button. No signing up.</h4>
                        </div>
                        <div style={{position: 'relative'}} className={"col-6"}>

                            <ParallaxBanner
                                className="parallax-class"
                                layers={[
                                    {
                                        children: <Image2 src={"circle.png"} alt="" className={"parallax-img"}/>,
                                        amount: -0.2,
                                    },
                                    {
                                        children: <Image src={TooEasy} alt="" className={"parallax-img"}/>,
                                        amount: -0.3,
                                    },
                                ]}
                                style={{height: 350, overflow: 'visible'}}
                            >
                            </ParallaxBanner>

                        </div>
                    </div>
                    <div style={{flex: 1, alignItems: 'center'}} className={"row"}>
                        <div style={{position: 'relative'}} className={"col-6 order-2"}>

                            <ParallaxBanner
                                className="parallax-class"
                                layers={[
                                    {
                                        children: <Image2 src={"circle.png"} alt="" className={"parallax-img"}/>,
                                        amount: -0.2,
                                    },
                                    {
                                        children: <Image src={MapMatchmaking} alt="" className={"parallax-img"}/>,
                                        amount: -0.3,
                                    },
                                ]}
                                style={{height: 350, overflow: 'visible'}}
                            >
                            </ParallaxBanner>
                        </div>
                        <div className={"col-6 order-1"}>
                            <h3 className={"left"}>Map Matchmaking</h3>
                            <h4 className={"description left"}>The distance is the only identification for each of the dialogue participants.</h4>
                        </div>
                    </div>

                    <div style={{flex: 1, alignItems: 'center'}} className={"row"}>
                        <div className={"col-6"}>
                            <h3 className={"right"}>Neighborhood <span style={{whiteSpace: 'nowrap'}} className={"break-row"}>Priority</span></h3>
                            <h4 className={"description right"}>When someone closer is ready to talk to you, we will connect you to them.</h4>
                        </div>
                        <div style={{position: 'relative'}} className={"col-6"}>

                            <ParallaxBanner
                                className="parallax-class"
                                layers={[
                                    {
                                        children: <Image2 src={"circle.png"} alt="" className={"parallax-img"}/>,
                                        amount: -0.2,
                                    },
                                    {
                                        children: <Image src={Neighborhood} alt="" className={"parallax-img"}/>,
                                        amount: -0.3,
                                    },
                                ]}
                                style={{height: 350, overflow: 'visible'}}
                            >
                            </ParallaxBanner>
                        </div>
                    </div>

                    <div style={{flex: 1, alignItems: 'center'}} className={"row"}>
                        <div style={{position: 'relative'}} className={"col-6 order-2"}>

                            <ParallaxBanner
                                className="parallax-class"
                                layers={[
                                    {
                                        children: <Image2 src={"circle.png"} alt="" className={"parallax-img"}/>,
                                        amount: -0.2,
                                    },
                                    {
                                        children: <Image src={Unlock} alt="" className={"parallax-img"}/>,
                                        amount: -0.3,
                                    },
                                ]}
                                style={{height: 350, overflow: 'visible'}}
                            >
                            </ParallaxBanner>
                        </div>
                        <div className={"col-6 order-1"}>
                            <h3 className={"left"}>Unlock <span style={{whiteSpace: 'nowrap'}} className={"break-row"}>The Ringtone</span></h3>
                            <h4 className={"description left"}>If you enable the call feature, you will not get more than a few calls a day, during appropriate hours. When the phone rings, it will probably be someone within walking distance.</h4>
                        </div>
                    </div>

                </div>
            </div>

    </Layout>
);

export default IndexPage
